html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers
*/
article, aside, main, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-moz-selection {
  color: #fff;
  background-color: var(--color-site-primary);
}

::selection {
  color: #fff;
  background-color: var(--color-site-primary);
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Noto Sans JP", sans-serif;
}


html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  /* display: flex; */
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  min-width: 1024px;
  min-height: 100vh;
  margin: 0;
  padding: 90px 0 0 100px;
  color: var(--color-txt-primary);
  background-color: #fff;
  -webkit-text-decoration-skip: none;
          text-decoration-skip: none;
  -webkit-text-size-adjust: 100%;
}
*{
  font-family: inherit;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
